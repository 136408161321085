import { useRef, useState } from 'react'
import { Delete } from '@mui/icons-material'
import {
  Stack,
  Button,
  Typography,
  TextField,
  InputAdornment,
  useMediaQuery,
  Box,
  Divider,
} from '@mui/material'
import { TypeOf, z } from 'zod'
import {
  Control,
  FieldErrors,
  UseFormHandleSubmit,
  UseFormRegister,
  useFieldArray,
} from 'react-hook-form'

import { theme } from '../../constants/theme'
import { COLOR } from '../../constants/color-constant'

import CreateBankAccount from './CreateBankAccount'
import ExistingBankAccountForm from './ExistingBankAccountForm'
import { BankAccount } from '../../models/campaign'

export const availableBanks = [
  'ธนาคารไทยพาณิชย์',
  'ธนาคารกสิกรไทย',
  'ธนาคารกรุงเทพ',
  'ธนาคารกรุงไทย',
  'ธนาคารทหารไทยธนชาต',
  'ธนาคารกรุงศรีอยุธยา',
  'ธนาคารเพื่อการเกษตรและสกหรณ์การเกษตร',
  'ธนาคารออมสิน',
  'ธนาคารอาคารสงเคราะห์',
  'ซิตี้แบงค์',
  'ธนาคาร ซีไอเอ็มบี ไทย',
  'ธนาคารยูโอบี',
  'ธนาคารเกียรตินาคินภัทร',
  'ธนาคารไอซีบีซี (ไทย)',
  'ธนาคารแลนด์ แอนด์ เฮ้าส์',
]

export const campaignProposalForm3Schema = z.object({
  budgetPlan: z.array(
    z.object({
      topic: z.string(),
      amount: z.number(),
      classifier: z.string(),
      budget: z.number(),
    })
  ),
})

export type CampaignProposalForm3Input = TypeOf<
  typeof campaignProposalForm3Schema
>

interface CampaignProposalForm3Props {
  register: UseFormRegister<CampaignProposalForm3Input>
  control: Control<CampaignProposalForm3Input>
  churchBankAccounts: BankAccount[]
  currBankAccount: BankAccount
  setBankAccount: (bankAccount: BankAccount) => void
  errors: FieldErrors<CampaignProposalForm3Input>
  onSubmit: UseFormHandleSubmit<CampaignProposalForm3Input>
  onSubmissionPass: () => void
  onClickRequestFix?: () => void
  onClickApprove?: () => void
  onBack: () => void
  viewOnly: boolean
  adminView: boolean
  hasNextPage: boolean
}

const MAX_BUDGET_PLAN = 15

function CampaignProposalForm3({
  register,
  control,
  churchBankAccounts,
  currBankAccount,
  setBankAccount,
  errors,
  onSubmit,
  onSubmissionPass,
  onClickRequestFix,
  onClickApprove,
  onBack,
  viewOnly,
  adminView,
  hasNextPage,
}: CampaignProposalForm3Props) {
  const screenLargerThanLg = useMediaQuery(theme.breakpoints.up('lg'))

  const bankRef = useRef<any>(null)
  const [bankError, setBankError] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>()

  const hasBankAccount = churchBankAccounts.length > 0

  const {
    fields: budgetPlan,
    append: addBudgetPlan,
    remove: removeBudgetPlan,
  } = useFieldArray({
    control,
    name: 'budgetPlan',
  })

  const onSubmitHandler = (data: CampaignProposalForm3Input) => {
    if (!validateBankAccount()) {
      bankRef.current?.scrollIntoView()
    } else {
      onSubmissionPass()
    }
  }

  const validateBankAccount = () => {
    const bankNameMissing = currBankAccount.bankName.trim() === ''
    const accountNameMissing = currBankAccount.accountName.trim() === ''
    const incorrectAccountNumber =
      currBankAccount.accountNumber.trim().length < 10 ||
      currBankAccount.accountNumber.trim().length > 15

    if (bankNameMissing) {
      setErrorMessage('กรุณากรอกชื่อธนาคาร')
    } else if (accountNameMissing) {
      setErrorMessage('กรุณากรอกชื่อบัญชี')
    } else if (incorrectAccountNumber) {
      setErrorMessage('เลขที่บัญชีธนาคารไม่ถูกต้อง')
    }

    const isBankAccountMissing =
      bankNameMissing || incorrectAccountNumber || accountNameMissing
    setBankError(isBankAccountMissing)
    return !isBankAccountMissing
  }

  return (
    <Box
      width='100%'
      component='form'
      id='campaign-proposal-form-2'
      onSubmit={onSubmit(onSubmitHandler)}
    >
      <Stack gap={'40px'}>
        <Stack flexDirection='row' flexWrap='wrap' gap='16px'>
          <Stack>
            <div
              style={{
                width: '300px',
              }}
            >
              <Typography variant='h3'>แผนการใช้เงิน</Typography>
            </div>
            <Typography variant='subtitle1' color={COLOR.GRAY_600}>
              สูงสุด {MAX_BUDGET_PLAN} แผน
            </Typography>
          </Stack>
          <Stack gap='16px'>
            {budgetPlan.map((b, i) => (
              <Stack
                key={b.id}
                direction={'row'}
                gap='22px'
                sx={{ flexWrap: { xs: 'wrap', lg: 'nowrap' } }}
                justifyContent={'space-between'}
              >
                <Typography variant='h5' whiteSpace={'nowrap'}>
                  ลำดับที่ {i + 1}
                </Typography>
                {!screenLargerThanLg && budgetPlan.length - 1 > 0 && (
                  <Button
                    color='error'
                    sx={{ minWidth: '0px' }}
                    onClick={() => removeBudgetPlan(i)}
                    disabled={viewOnly}
                  >
                    <Delete fontSize='small' />
                  </Button>
                )}
                <TextField
                  size='small'
                  fullWidth={true}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>หัวข้อ</InputAdornment>
                    ),
                  }}
                  {...register(`budgetPlan.${i}.topic`)}
                  disabled={adminView}
                  sx={{
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: '#212121',
                    },
                  }}
                ></TextField>
                <TextField
                  type='number'
                  size='small'
                  fullWidth={true}
                  inputProps={{
                    pattern: '[0-9]*',
                    inputMode: 'numeric',
                    min: 0,
                    step: 'any',
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>จำนวน</InputAdornment>
                    ),
                  }}
                  {...register(`budgetPlan.${i}.amount`, {
                    valueAsNumber: true,
                  })}
                  disabled={adminView}
                  sx={{
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: '#212121',
                    },
                  }}
                ></TextField>
                <TextField
                  size='small'
                  fullWidth={true}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        ลักษณะนาม
                      </InputAdornment>
                    ),
                  }}
                  {...register(`budgetPlan.${i}.classifier`)}
                  disabled={adminView}
                  sx={{
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: '#212121',
                    },
                  }}
                ></TextField>
                <TextField
                  type='number'
                  size='small'
                  fullWidth={true}
                  inputProps={{
                    pattern: '[0-9]*',
                    inputMode: 'numeric',
                    min: 0,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>งบประมาณ</InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position='end'>บาท</InputAdornment>
                    ),
                  }}
                  {...register(`budgetPlan.${i}.budget`, {
                    valueAsNumber: true,
                  })}
                  disabled={adminView}
                  sx={{
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: '#212121',
                    },
                  }}
                />
                {screenLargerThanLg && budgetPlan.length - 1 > 0 && (
                  <Button
                    color='error'
                    sx={{ minWidth: '0px' }}
                    onClick={() => removeBudgetPlan(i)}
                    disabled={viewOnly}
                  >
                    <Delete fontSize='small' />
                  </Button>
                )}
              </Stack>
            ))}
            {budgetPlan.length < MAX_BUDGET_PLAN && (
              <Button
                variant='outlined'
                fullWidth={false}
                sx={{ maxWidth: '220px', alignSelf: 'end' }}
                onClick={() =>
                  addBudgetPlan({
                    topic: '',
                    amount: 0,
                    classifier: '',
                    budget: 0,
                  })
                }
                disabled={viewOnly}
              >
                เพิ่ม
              </Button>
            )}
          </Stack>
        </Stack>
        <Divider />
        <Stack flexDirection='row' flexWrap='wrap' gap='16px' ref={bankRef}>
          <Stack flexDirection='row' flexWrap='wrap' width='100%' gap='16px'>
            <div>
              <span>
                <Typography
                  display='inline'
                  variant='h3'
                  color={COLOR.PRIMARY_1}
                >
                  *{' '}
                </Typography>
                <Typography display='inline' variant='h3'>
                  ข้อมูลบัญชีธนาคารสำหรับโครงการ
                </Typography>
              </span>
              {bankError && (
                <Typography variant='subtitle1' color='error'>
                  {errorMessage}
                </Typography>
              )}
            </div>
            {!hasBankAccount ? (
              <Stack gap={'24px'} maxWidth={'650px'}>
                {!adminView && (
                  <Typography variant='h5'>
                    โบสถ์ของคุณยังไม่มีบัญชีธนาคารที่ลงทะเบียนไว้
                    กรุณาสร้างบัญชีธนาคารใหม่สำหรับโครงการนี้
                  </Typography>
                )}
                <CreateBankAccount
                  viewOnly={viewOnly}
                  bankAccount={currBankAccount}
                  handleBankChange={(b) => {
                    setBankError(false)
                    setBankAccount(b)
                  }}
                />
              </Stack>
            ) : (
              <ExistingBankAccountForm
                viewOnly={viewOnly}
                adminView={adminView}
                bankAccounts={churchBankAccounts}
                bankAccount={currBankAccount}
                handleBankChange={(b) => {
                  setBankError(false)
                  setBankAccount(b)
                }}
              />
            )}
          </Stack>
        </Stack>
        <Divider />
        <Stack
          justifyContent='space-between'
          flexDirection={{ xs: 'column', sm: 'row' }}
          gap={{ xs: '10px' }}
        >
          <Button variant='outlined' fullWidth={false} onClick={() => onBack()}>
            <Typography variant='h6'>{`< กลับ`}</Typography>
          </Button>
          {adminView ? (
            <Stack
              flexDirection={'row'}
              gap={'10px'}
              justifyContent='space-between'
            >
              <Button
                variant='outlined'
                color='error'
                onClick={onClickRequestFix}
              >
                <Typography variant='h6'>Request Fix</Typography>
              </Button>
              <Button
                variant='outlined'
                color='primary'
                onClick={onClickApprove}
              >
                <Typography variant='h6'>Approve</Typography>
              </Button>
            </Stack>
          ) : null}

          {!viewOnly && !hasNextPage ? (
            <Button
              variant='outlined'
              fullWidth={false}
              type='submit'
              color='secondary'
            >
              <Typography variant='h6'>ส่งคำขอ</Typography>
            </Button>
          ) : null}
          {hasNextPage ? (
            <Button variant='outlined' fullWidth={false} type='submit'>
              <Typography variant='h6'>{'ถัดไป >'}</Typography>
            </Button>
          ) : null}
        </Stack>
      </Stack>
    </Box>
  )
}

export default CampaignProposalForm3
